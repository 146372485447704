.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Adversal";
  src: url("./assets/fonts/Adversal/adversal.otf");
}

@font-face {
  font-family: "Trap";
  src: url("./assets/fonts/Trap/Trap-Regular.otf");
}

.toggle-box {
    background-color: #fff;
    border-radius: 12px;
    width: 232px;
    height: 44px;
    display: flex;    
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
