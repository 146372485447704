
.boxStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  background-color: #252525;
  width: 230px;
  border-radius: 20px;
  padding: 24px;
}